<template>
    <div>
        <v-container class="paper-border">
            <v-row>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ Contract__sellertitle }}</span>
                    <span :class="Contract__office_id ? '' : 'red--text'" v-if="[1,5].includes(Contract__salestype_id)">{{ Contract__office_id ? offices.find((d) => d.Office.id == Contract__office_id).Office.title : $t('message.valueMissing') }}</span>
                    <span :class="Contract__supplier_id ? '' : 'red--text'" v-if="[2].includes(Contract__salestype_id)">{{ Contract__supplier_id ? allSuppliers.find((d) => d.Supplier.id == Contract__supplier_id).Supplier.title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                    <span :class="Contract__salescontact_id ? '' : 'red--text'">{{ Contract__salescontact_id ? findSalesColleagueById(Contract__salescontact_id).Salesperson.name : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span :class="Contract__contractdate ? '' : 'red--text'">{{ Contract__contractdate ? formatDate(Contract__contractdate) : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.number') }}</span>
                    <span :class="Contract__title ? '' : 'red--text'">{{ Contract__title ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t(buyerCaption) }}</span>
                    <span :class="Contract__customer_id ? '' : 'red--text'">{{ Contract__customer_id ? findBuyerById(Contract__customer_id).Customer.title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t(deliveryCaption) }}</span>
                    <span :class="Contract__deliveryaddress_id != null ? null : 'red--text'">{{ Contract__deliveryaddress_id != null ? $t(Contract__Deliveryaddress_address) : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                    <span :class="Contract__paymentstatement_id ? '' : 'red--text'">{{ Contract__paymentstatement_id != null && Contract__paymentstatement_id != '' ? findBuyerPaymentTermById(Contract__paymentstatement_id).Paymentterm.title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.shipment') }}</span>
                    <span :class="Contract__shippingweekstatement ? '' : 'red--text'">{{ Contract__shippingweekstatement ? Contract__shippingweekstatement : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0" v-if="[1,5].includes(Contract__salestype_id)">
                    <span class="font-weight-bold">{{ $t('message.paidPresentedTo') }}</span>
                    <span :class="Contract__Bank_id ? '' : 'red--text'">
                        <span style="white-space: pre">{{ Contract__Bank_id ? contractBank : $t('message.valueMissing') }}</span>
                    </span>
                </v-col>
                <v-col cols="3" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.countryOriginDestination') }}</span>
                    <span :class="Contract__productorigin_id || Contract__destinatione_id ? '' : 'red--text'" v-if="Loadingport__country == originCountry && originCountry != null">
                        {{ originCountry != null ? originCountry : $t('message.valueMissing') }} /
                        {{ destinationCountry != null ? destinationCountry : $t('message.valueMissing') }}
                    </span>
                    <span :class="Contract__productorigin_id || Contract__destinatione_id ? '' : 'red--text'" v-else>
                        {{ Loadingport__country != null ? Loadingport__country : $t('message.valueMissing') }} /
                        {{ destinationCountry != null ? destinationCountry : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="3" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold font-xs">{{ $t('message.termsOfDelivery') }}</span>
                    <span :class="contractIncoterm ? '' : 'red--text'">
                        {{ contractIncoterm ? contractIncoterm : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-simple-table
                    class="pt-2"
                    dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.species') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.specification') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.size') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.grade') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.quantity') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.unitPrice') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.amount') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in Salesitems" :key="index" v-if="item.type == 'default'">
                            <td :class="(item.Species.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Species.title != null ? item.Species.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Spec.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Spec.title != null ? item.Spec.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Size.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Size.title != null ? item.Size.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Grade.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Grade.title != null  ? item.Grade.title : $t('message.valueMissing') }}</td>
                            <td class="px-0 text-right border-0">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemqty, uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</td>
                            <td class="px-0 text-right border-0">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitprice,currencyFormatPrecision) : '' }}</td>
                            <td class="px-0 text-right border-0">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) + ')' }}</td>
                        </tr>
                        <tr v-else-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0">
                            <td></td>
                            <td></td>
                            <td class="px-0">{{ item.Salesitem.size }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</td>
                        </tr>
                        <tr>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="px-0 font-weight-bold border-top-2">
                                <span class="pr-2">{{ $t('message.total') }}</span>
                                <span v-if=" [1,2,3,5].includes(Contract__containersize_id) ">{{ Contract__containercount }}</span>
                                <span v-if=" [1,2,3,5].includes(Contract__containersize_id) "> X </span>
                                <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Contract__containersize_id).Containersize.title }}</span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2">
                                <span v-for="(total,index) in itemsTotalQty">
                                        {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                </span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ Contract__currency_id != null ? allCurrencies.find((currency) => currency.Currency.id == Contract__currency_id).Currency.code : '' }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.remarks') }}</span>
                    <span :class="Contract__note != null ? '' : 'red--text'" style="white-space: pre; margin-left: -4px !important;">
                        {{ Contract__note != null ? Contract__note.trim() : $t('message.valueMissing') }}
                        {{ specificationNotes != null ? "\n" + specificationNotes : '' }}
                    </span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.certification') }}</span>
                    <span :class="Contract__fsc ? '' : 'red--text'">{{ certification }}</span>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    // import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, numberFormat} from "../../helpers/helpers";
    // import { api } from "../../api";
    import {mapFieldsPrefixed,mapMultiRowFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'

    export default {
        name: "ContractPreview",
        data(){
            return {

            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('contract', {
                Contract__id: 'Contract.id',
                Contract__Bank_id: 'Contract.Bank.id',
                Contract__Bank_title: 'Contract.Bank.title',
                Contract__Bank_account: 'Contract.Bank.account',
                Contract__Bank_swiftcode: 'Contract.Bank.swiftcode',
                Contract__Bank_beneficiary: 'Contract.Bank.beneficiary',
                Contract__Deliveryaddress_address: 'Contract.Deliveryaddress.address',
                Contract__buyeraddresstype: 'Contract.buyeraddresstype',
                Contract__contractdate: 'Contract.contractdate',
                Contract__commission_per_line: 'Contract.commission_per_line',
                Contract__containercount: 'Contract.containercount',
                Contract__containersize_id: 'Contract.containersize_id',
                Contract__currency_id: 'Contract.currency_id',
                Contract__customer_id: 'Contract.customer_id',
                Contract__destinatione_id: 'Contract.destinatione_id',
                Contract__deliveryaddress_id: 'Contract.deliveryaddress_id',
                Contract__incoterm_id: 'Contract.incoterm_id',
                Contract__incotermport: 'Contract.incotermport',
                Contract__fsc: 'Contract.fsc',
                Contract__loadingport_id: 'Contract.loadingport_id',
                Contract__margin: 'Contract.margin',
                Contract__note: 'Contract.note',
                Contract__office_id: 'Contract.office_id',
                Contract__partial_no: 'Contract.partial_no',
                Contract__paymentstatement_id: 'Contract.paymentstatement_id',
                Contract__productorigin_id: 'Contract.productorigin_id',
                Contract__revision_no: 'Contract.revision_no',
                Contract__salescontact_id: 'Contract.salescontact_id',
                Contract__salestype_id: 'Contract.salestype_id',
                Contract__sellertitle: 'Contract.sellertitle',
                Contract__shippingport_id: 'Contract.shippingport_id',
                Contract__shipweekdate: 'Contract.shipweekdate',
                Contract__shippingweekstatement: 'Contract.shippingweekstatement',
                Contract__showbank: 'Contract.showbank',
                Contract__supplier_id: 'Contract.supplier_id',
                Contract__title: 'Contract.title',
                Deliveryaddress__address: 'Contract.Deliveryaddress.address',
                Loadingport__country: 'Contract.Loadingport.country'
            },'statePrefix'),
            ...mapGetters('buyer',{
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries',
                findCountryById: 'findCountryById'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('paymentterm',{
                findBuyerPaymentTermById: 'findBuyerPaymentTermById'
            }),
            ...mapGetters('salescolleague',{
                findSalesColleagueById: 'findSalesColleagueById'
            }),
            ...mapMultiRowFieldsPrefixed('salesitem', {
                Salesitems: 'Salesitems'
            },'statePrefix'),
            ...mapFieldsPrefixed('salesitem',{
                specificationNotes: 'specificationNotes'
            },'statePrefix'),
            ...mapFieldsPrefixed('salesitem',{
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalCommission: 'itemsTotalCommission',
                itemsTotalCost: 'itemsTotalCost',
                itemsTotalMargin: 'itemsTotalMargin',
                itemsTotalQty: 'itemsTotalQty',
            },'statePrefix'),
            ...mapGetters('shippingport', {
                findShippingPortById: 'findShippingPortById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters([
                'buyerCaptionTypes',
                'certifications',
                'containerSizes',
                'currencies',
                'deliveryAddressTypes',
                'incoterms',
                'offices',
                'uofmQty'
            ]),
            buyerCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype).title_first
            },
            certification() {
                let certification = this.certifications.find(certification => certification.Certification.id == this.Contract__fsc)
                if( certification ){
                    return certification.Certification.title
                }
                return this.$t('message.valueMissing')
            },
            contractIncoterm() {
                let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Contract__incoterm_id)?.Incoterm?.title;
                let incotermport = null;
                switch(this.Contract__incotermport){
                    case 0: //POD
                        if(this.Contract__shippingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Contract__shippingport_id).Shippingport.title
                        }
                        break;
                    case 1: //POL
                        if(this.Contract__loadingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Contract__loadingport_id).Shippingport.title
                        }
                        break;
                    case 2:
                        return null
                        break
                }
                return incoterm + incotermport;
            },
            contractBank(){
                let bank = '';
                if(this.Contract__Bank_id != null){
                    if(this.Contract__Bank_title){ bank += this.$t('message.banker') + ': ' + this.Contract__Bank_title.trim()}
                    if(this.Contract__Bank_swiftcode){ bank += "\n" +  this.$t('message.swiftCode') + ': ' + this.Contract__Bank_swiftcode.trim()}
                    if(this.Contract__Bank_account){ bank += "\n" + this.$t('message.accountNo') + ': ' + this.Contract__Bank_account.trim()}
                    if(this.Contract__Bank_beneficiary){ bank += "\n" + this.$t('message.beneficiary') + ': ' + this.Contract__Bank_beneficiary.trim()}
                }
                return bank
            },
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
            },
            deliveryCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype).title_last
            },
            destinationCountry() {
                let country = this.findCountryById(this.Contract__destinatione_id)?.Country?.name
                return country
            },
            originCountry() {
                let country = this.findCountryById(this.Contract__productorigin_id)?.Country?.name
                return country
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_contract')
            },
        },
        methods: {
            ...mapActions('country', {
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('contract',{
                saveContract: 'saveContract'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('supplier', {
                getAllSuppliers: 'getAllSuppliers'
            }),
            createSpecification(item){
                if(item.type == 'credit'){
                    return item.Spec.title != null ? item.Spec.title : null
                }
                let specification = null
                if (item.Species.title != null) {
                    specification = item.Species.title
                }
                if (item.Spec.title != null) {
                    specification = specification == null ? item.Spec.title : specification + ', '+ item.Spec.title
                }
                return specification
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created(){
            if(this.allCountries.length == 0) this.getAllCountries();
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
        }
    }
</script>

<style>
    .paper-border {
        border: dashed 1px darkgrey;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
</style>